import api from './Api';
import getAuthToken from './helpers';

class CollaboratorService {
  constructor() {
    this.api = api;
  }

  getCollaboratorList(companyId, page, filter, filterValue, statusLogin, resetFilter) {
    let url = `/companies/${companyId}/collaborators?page=${page}&paginate=10`;

    if (!resetFilter) {
      if (filter && filterValue) {
        url += `&filter=${filter}&value=${encodeURIComponent(filterValue)}`;
      }

      if (statusLogin) {
        url += `&statusLogin=${statusLogin}`;
      }
    }

    return this.api.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }

  inactivateCollaborator(collaboratorId) {
    return this.api.put(`/collaborators/${collaboratorId}/inactivate`, {}, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }

  createCollaborator(collaborator) {
    return this.api.post('/collaborators/create-collaborator', collaborator, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
  }
}

export default CollaboratorService;
